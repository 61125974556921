.header {
    position: relative;
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  header >* h1 {
    color: rgb(246, 243, 240);
  }

  header >* h4 {
    margin: 0;
  }
  
  .content {
    position: relative;
    width: 100%;
    text-align: center;
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
  }
  
  
  .title-overlay {
    display: inline-block;
    padding: 20px 30px;
    background-color: rgba(51, 57, 66, 0.75);  
    color: rgb(246, 243, 240); 
    margin-bottom: 10px;  
    border-radius: 15px; 
  }
  
  .subtitle-overlay {
    display: inline-block;
    padding: 10px 20px;
    background-color: rgba(51, 57, 66, 0.75);  
    color: rgb(246, 243, 240); 
    border-radius: 15px; 
  }

  @media (min-width: 576px) {
    .title-overlay, .subtitle-overlay {
      width: 70%;  
    }
  }
  
  .title-overlay h1 {
    margin: 0;
  }
  
  .subtitle-overlay p {
    margin: 0;
  }
  