body {
  background-color: #F6F3F0;
}

h1 {
  font-size: 80px;
  color: rgb(51, 57, 66);
  font-family: bodoni-moda, "bodoni moda", serif;
}

/* Adjust font size for tablets */
@media (max-width: 1024px) {
  h1 {
    font-size: 60px;
  }
}

/* Adjust font size for mobile devices */
@media (max-width: 768px) {
  h1 {
    font-size: 40px;
  }
}

h2 {
  font-size: 60px;
  color: rgb(51, 57, 66); /* Matches the h1 color */
  font-family: bodoni-moda, "bodoni moda", serif;
}

/* Adjust font size for tablets */
@media (max-width: 1024px) {
  h2 {
    font-size: 45px;
  }
}

/* Adjust font size for mobile devices */
@media (max-width: 768px) {
  h2 {
    font-size: 30px;
  }
}

.foodceptionYoutubeVideoPlayer{
  width: 100%;
}

.foodceptionYoutubeVideoPlayerIframe{
  width: 100%;
}

.foodceptionSubCategoryTitle {
  background-color: rgba(51, 57, 66, 0.75);
  border: 1px solid rgba(51, 57, 66, 0.75);
  color: white;
  display: inline-flex;  
  justify-content: center;  
  align-items: center;  
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.foodceptionSubCategoryTitle:hover {
  background-color: white;
}

.foodceptionSubCategoryTitle:hover a {
  color: rgba(51, 57, 66, 0.75);
}

.foodceptionSubCategoryTitle a{
  color: white;
}

.foodceptionSubCategoryTitle a:hover {
  text-decoration: none;
  color: rgba(51, 57, 66, 0.75);
}

 


.link-button {
  background: none;
  border: none;
  color: #000; 
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  vertical-align: baseline;
}

.link-button.underlined {
  text-decoration: underline;
}

.link-button:hover,
.link-button:focus,
.link-button:active,
.link-button:visited {
  text-decoration: underline; 
  color: #000;  
}

.link-button:focus {
  outline: none;
}

.foodception-badge-top-right {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #66bb6a; 
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 0.85rem;
  font-weight: 600;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  transition: background-color 0.3s;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); 
  text-decoration: none;
}

.foodception-badge-top-right:hover {
  background-color: #388e3c;  
  cursor: pointer;
}
 

 



